var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "tit": _vm.product.category,
      "bg": "/images/sub/visual/sv--product.jpg"
    }
  }), _c('section', {
    staticClass: "section--sm section--first"
  }, [_c('v-container', [_c('button', {
    staticClass: "text-btn d-flex align-center",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('i', {
    staticClass: "icon icon-left-arrow mr-8"
  }), _vm._v("Back to product series")]), _c('div', {
    staticClass: "mt-12 mt-lg-20"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mb-20 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "prd-slide-wrap rounded mb-12 mb-lg-20"
  }, [_c('div', {
    staticClass: "prd-slide"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.product.files, function (file, idx) {
    return _c('div', {
      key: idx,
      staticClass: "swiper-slide",
      on: {
        "click": function ($event) {
          return _vm.showImgDialog(file.url);
        }
      }
    }, [_c('div', {
      staticClass: "thumb white"
    }, [_c('v-img', {
      staticClass: "thumb__inner border rounded",
      attrs: {
        "src": file.url
      }
    })], 1)]);
  }), 0)])])]), _c('div', {
    staticClass: "prd-thumb-slide px-50 px-lg-76"
  }, [_c('v-btn', {
    staticClass: "prd-slide-btn prd-prev",
    attrs: {
      "icon": "",
      "tile": "",
      "outlined": "",
      "color": "grey-e"
    }
  }, [_c('i', {
    staticClass: "icon icon-left-arrow2"
  })]), _c('v-btn', {
    staticClass: "prd-slide-btn prd-next",
    attrs: {
      "icon": "",
      "tile": "",
      "outlined": "",
      "color": "grey-e"
    }
  }, [_c('i', {
    staticClass: "icon icon-right-arrow2"
  })]), _c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.product.files, function (file, idx) {
    return _c('div', {
      key: idx,
      staticClass: "swiper-slide rounded rounded--sm prd-thumb"
    }, [_c('div', {
      staticClass: "thumb white",
      staticStyle: {
        "cursor": "pointer"
      }
    }, [_c('v-img', {
      staticClass: "thumb__inner",
      attrs: {
        "src": file.url
      }
    })], 1)]);
  }), 0)])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "w-100 pl-lg-38"
  }, [_c('h4', {
    staticClass: "tit--sm font-weight-bold line-height-1 mb-4 mb-lg-10"
  }, [_vm._v(" " + _vm._s(_vm.product.subject) + " ")]), _c('p', {
    staticClass: "page-text line-height-15 mb-12 mb-lg-20"
  }, [_vm._v(" " + _vm._s(_vm.product.summary) + " ")]), _c('ul', {
    staticClass: "prd__des page-text--sm line-height-15 color-grey-7"
  }, [_c('li', [_vm._v(_vm._s(_vm.product.description[0]))]), _c('li', [_vm._v(_vm._s(_vm.product.description[1]))]), _c('li', [_vm._v(_vm._s(_vm.product.description[2]))]), _c('li', [_vm._v(_vm._s(_vm.product.description[3]))]), _c('li', [_vm._v(_vm._s(_vm.product.description[4]))])]), _c('v-divider', {
    staticClass: "border-color-dark my-20 my-lg-40"
  }), _c('div', {
    staticClass: "d-flex align-center justify-space-between mb-12 mb-lg-20"
  }, [_c('h5', {
    staticClass: "tit-txt font-weight-bold letter-spacing-6 line-height-14"
  }, [_vm._v(" SIZE & "), _c('br'), _vm._v(" SPECIFICATIONS ")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('v-btn', {
    staticClass: "sizes-prev mr-4 mr-lg-10",
    attrs: {
      "icon": "",
      "small": "",
      "outlined": "",
      "color": "grey-7"
    }
  }, [_c('i', {
    staticClass: "icon icon-left-arrow-grey icon-left-arrow-grey--sm"
  })]), _c('v-btn', {
    staticClass: "sizes-next",
    attrs: {
      "icon": "",
      "small": "",
      "outlined": "",
      "color": "grey-7"
    }
  }, [_c('i', {
    staticClass: "icon icon-right-arrow-grey icon-left-arrow-grey--sm"
  })])], 1)]), _c('div', {
    staticClass: "rounded pa-20 px-lg-16 py-lg-20 grey-f5 mb-14 mb-lg-26"
  }, [_c('div', {
    staticClass: "sizes-slide"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.product.size, function (el, idx) {
    return _c('div', {
      key: idx,
      staticClass: "swiper-slide"
    }, [_c('div', {
      staticClass: "size",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.showDialog(el);
        }
      }
    }, [_c('p', {
      staticClass: "font-size-12 font-size-lg-14"
    }, [_vm._v(" " + _vm._s(el.name) + " ")])])]);
  }), 0)])])]), _c('div', {
    staticClass: "d-flex justify-end"
  }, [_c('btn-primary', {
    attrs: {
      "to": "/inquiry",
      "tit": "제품문의"
    }
  })], 1)], 1)])], 1)], 1)])], 1), _c('section', {
    staticClass: "section--sm section--last"
  }, [_c('v-container', [_c('h5', {
    staticClass: "tit-txt font-weight-bold letter-spacing-6 line-height-1"
  }, [_vm._v(" DETAIL VIEW ")]), _c('v-divider', {
    staticClass: "border-color-secondary my-20 my-lg-40"
  }), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.product.content)
    }
  })], 1)], 1), _vm.show == true ? _c('v-dialog', {
    attrs: {
      "max-width": "793px",
      "persistent": ""
    },
    model: {
      value: _vm.size,
      callback: function ($$v) {
        _vm.size = $$v;
      },
      expression: "size"
    }
  }, [_c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.show = false;
      }
    }
  }, [_vm._v("mdi-close")]), _c('div', {
    staticClass: "pa-20 pa-md-60"
  }, [_c('p', {
    staticClass: "page-text--lg font-weight-bold"
  }, [_vm._v(_vm._s(_vm.size.name))]), _c('v-divider', {
    staticClass: "border-color-secondary my-12 my-lg-20"
  }), _c('p', {
    staticClass: "page-text line-height-15",
    staticStyle: {
      "white-space": "pre-line"
    }
  }, [_vm._v(" " + _vm._s(_vm.size.content) + " ")])], 1)], 1) : _vm._e(), _vm.showImg == true ? _c('v-dialog', {
    attrs: {
      "max-width": "793px",
      "persistent": ""
    },
    model: {
      value: _vm.img,
      callback: function ($$v) {
        _vm.img = $$v;
      },
      expression: "img"
    }
  }, [_c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.showImg = false;
      }
    }
  }, [_vm._v("mdi-close")]), _c('v-img', {
    attrs: {
      "src": _vm.img
    },
    on: {
      "click": function ($event) {
        _vm.showImg = false;
      }
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }